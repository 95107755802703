// $internalSpace: 6.25rem;
$internalSpace: 3.25rem;

@mixin breakpoint($name) {
    @if $name =="vrsmall" {
        @media (max-width: 574px) {
            @content;
        }
    }

    @else if $name =="small" {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $name =="mdsmall" {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $name =="medium" {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $name =="mdlarge" {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else if $name =="large" {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

.container {
    width: 100%;

    @include breakpoint(vrsmall) {
        margin: 0 15px;
    }

    @include breakpoint(small) {
        max-width: 540px;
    }

    @include breakpoint(mdsmall) {
        max-width: 720px;
    }

    @include breakpoint(medium) {
        max-width: 960px;
    }

    @include breakpoint(mdlarge) {
        max-width: 1140px;
    }

    @include breakpoint(large) {
        max-width: 1320px;
    }
}

.erroForm {
    color: #F9A825;
}

#banner {
    max-width: 100%;
    display: block;
    height: auto;
}

#sectionA {
    background: #387F77;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        padding: $internalSpace 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 50px;

        @include breakpoint(vrsmall) {
            flex-direction: column;  
        }

        @include breakpoint(small) {
            flex-direction: column;
        }

        @include breakpoint(mdsmall) {
            flex-direction: column;
        }

        @include breakpoint(medium) {
            flex-direction: row;
        }

        .text {
            p {
                color: #fff;
                font-weight: 400;

                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }
            }
        }

        .logos {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;

            img:nth-child(1) {
                max-width: 100%;
                display: block;
                height: auto;
                border-top-right-radius: 38px;
                border-bottom-left-radius: 38px;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 4px;
                // border: 4px solid #87C342;

                @include breakpoint(vrsmall) {
                    max-width: 360px;
                }

                @include breakpoint(small) {
                    max-width: 420px;
                }

                @include breakpoint(mdsmall) {
                    max-width: 480px;
                }

                @include breakpoint(medium) {
                    max-width: 510px;
                }

                @include breakpoint(mdlarge) {
                    max-width: 540px;
                }
            }

            img:nth-child(2) {
                max-width: 100%;
                height: auto;
                display: block;
                border-radius: 55px;
                // border: 4px solid #87C342;

                @include breakpoint(vrsmall) {
                    max-width: 360px;
                }

                @include breakpoint(small) {
                    max-width: 420px;
                }

                @include breakpoint(mdsmall) {
                    max-width: 480px;
                }

                @include breakpoint(medium) {
                    max-width: 510px;
                }

                @include breakpoint(mdlarge) {
                    max-width: 540px;
                }
            }
        }
    }
}

#sectionB {
    padding: $internalSpace 0;
    background-color: #87C342;
    display: flex;
    justify-content: center;

    @include breakpoint(vrsmall) {
        
    }

    .container {

        .title {
            font-weight: 400;
            font-style: italic;
            font-size: 4.6875rem;
            text-align: center;
            line-height: 80px;
            color: #387F77;
            margin-bottom: 120px;

            strong {
                font-weight: 600;
                font-style: italic;
                color: #013853;
            }
        }

        .cards {
            display: flex;
            justify-content: space-evenly;
            gap: 50px;

            @include breakpoint(vrsmall) {
                flex-direction: column;
                align-items: center;
            }

            @include breakpoint(small) {
                flex-direction: column;
                align-items: center;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                align-items: center;
            }

            @include breakpoint(medium) {
                flex-direction: row;
                align-items: flex-start;
            }

            .card {
                width: 300px;
                text-align: center;

                img {
                    width: 200px;
                }

                h3 {
                    font-size: 1.5625rem;
                    font-weight: 400;
                    text-align: center;
                    color: #387F77;
                    margin-top: 25px;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

#sectionC {
    padding: $internalSpace 0;
    background: url(../imgs/bg-1.png) center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {
        .title {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: #D5E7B0;
            margin-bottom: 120px;

            strong {
                font-weight: 600;
                color: #ffffff;
            }

            @include breakpoint(vrsmall) {
                font-size: 3.4375rem;
            }

            @include breakpoint(small) {
                font-size: 4.6875rem;
            }
        }

        .cards {
            display: flex;
            justify-content: space-evenly;

            @include breakpoint(vrsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(small) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(medium) {
                flex-direction: row;
                align-items: flex-start;
            }

            .card {
                width: 300px;
                text-align: center;

                img {
                    width: 200px;
                }

                h3 {
                    font-size: 1.5625rem;
                    font-weight: 400;
                    text-align: center;
                    color: #fff;
                    margin-top: 25px;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .rodape {
            padding-top: 80px;

            p {
                color: #D5E7B0;
                font-weight: 800;
                text-align: center;
            }
        }
    }
}

#sectionD {
    padding: $internalSpace 0;
    background: url(../imgs/bg-3.jpg) fixed center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100%;

    .container {
        display: flex;
        gap: 50px;

        @include breakpoint(vrsmall) {
            flex-direction: column;
            align-items: center;
        }

        @include breakpoint(small) {
            flex-direction: column;
            align-items: center;
        }

        @include breakpoint(mdsmall) {
            flex-direction: column;
        }

        @include breakpoint(medium) {
            flex-direction: row;
            align-items: center;
        }

        .header {
            color: #fff;

            @include breakpoint(vrsmall) {
                max-width: 90%;
            }

            @include breakpoint(small) {
                max-width: 90%;
            }

            @include breakpoint(medium) {
                width: 50%;
            }

            .titulo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 25px;

                img {
                    height: 150px;
                }
            }

            h2 {
                font-size: 2.5625rem;
                font-weight: 400;
                margin: 60px 0;

                strong {
                    font-weight: 600;
                    font-style: italic;
                }
            }

            p {
                font-weight: 400;
                margin: 25px 0 0 0;

                @include breakpoint(vrsmall) {
                    text-align: center;
                }

                @include breakpoint(small) {
                    text-align: center;
                }

                @include breakpoint(medium) {
                    text-align: left;
                }
            }
        }

        .content {
            @include breakpoint(vrsmall) {
                max-width: 90%;
            }

            @include breakpoint(small) {
                max-width: 90%;
            }

            @include breakpoint(medium) {
                max-width: 50%;
            }

            .col {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h3 {
                    color: #fff;
                    margin-bottom: 15px;
                    text-align: center;

                    @include breakpoint(vrsmall) {
                        font-weight: 500;
                        font-size: 1.4375rem;
                    }

                    @include breakpoint(small) {
                        font-weight: 500;
                        font-size: 1.4375rem;
                    }

                    @include breakpoint(medium) {
                        font-weight: 600;
                        font-size: 1.75rem;
                    }
                }

                a.btn {
                    text-decoration: none;
                    color: #013853;
                    font-size: 1.4375rem;
                    font-weight: 500;
                    margin-bottom: 30px;
                    background-color: #D5E7B0;
                    text-align: center;
                    border-radius: 36.095px;
                    transition: color .40s ease-in-out, background-color .40s ease-in-out;

                    &:hover {
                        color: #D5E7B0;
                        background-color: #013853;
                        transition: color .40s ease-in-out, background-color .40s ease-in-out;
                    }

                    @include breakpoint(vrsmall) {
                        padding: 20px 100px;
                    }

                    @include breakpoint(small) {
                        padding: 20px 150px;
                    }
                }

                .btn {

                    cursor: pointer;
                }
            }
        }
    }
}

#sectionE {
    background: url(../imgs/bg-4.jpg) top center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {
        padding: $internalSpace 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: 400;
            font-size: 4.6875rem;
            text-align: center;
            color: #fff;
            margin-bottom: 120px;

            strong {
                font-weight: 600;
                color: #D5E7B0;
            }
        }

        .rowCard {
            max-width: 1100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px 0;
            gap: 50px;

            @include breakpoint(vrsmall) {
                flex-direction: column;
            }

            @include breakpoint(small) {
                flex-direction: column;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }

            .texto {

                @include breakpoint(vrsmall) {
                    max-width: 100%;
                }

                @include breakpoint(small) {
                    max-width: 100%;
                }

                @include breakpoint(medium) {
                    max-width: 50%;
                }

                h3 {
                    font-weight: 400;
                    color: #D5E7B0;

                    @include breakpoint(vrsmall) {
                        font-size: 2.0625rem;
                    }

                    @include breakpoint(small) {
                        font-size: 2.6875rem;
                    }

                    strong {
                        font-weight: 600;
                        font-style: italic;
                    }
                }

                p {
                    margin-top: 30px;
                    color: #fff;
                    font-weight: 400;

                    strong {
                        color: #D5E7B0;
                        font-weight: 600;
                    }
                }
            }
        }

        .micInd,
        .peqEmp {

            @include breakpoint(vrsmall) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(small) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(medium) {
                flex-direction: row-reverse;
                text-align: left;
            }
        }

        .micEmp {

            @include breakpoint(vrsmall) {
                text-align: center;
            }

            @include breakpoint(small) {
                text-align: center;
            }

            @include breakpoint(mdsmall) {
                text-align: center;
            }

            @include breakpoint(medium) {
                text-align: right;
            }
        }
    }

    .imagem {
        img {
            max-width: 100%;
            display: block;
            height: auto;
            border-top-right-radius: 38px;
            border-bottom-left-radius: 38px;
            border-bottom-right-radius: 4px;
            border-top-left-radius: 4px;
            border: 4px solid #D5E7B0;
        }

        @include breakpoint(vrsmall) {
            max-width: 360px;
        }

        @include breakpoint(small) {
            max-width: 420px;
        }

        @include breakpoint(mdsmall) {
            max-width: 480px;
        }

        @include breakpoint(medium) {
            max-width: 540px;
        }
    }

    .aviso {
        width: 80%;
        margin-top: $internalSpace;
        background-color: #D5E7B0;
        font-size: 1.25rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(vrsmall) {
            flex-direction: column;
            padding: 30px 25px;
            gap: 25px;
            width: 70%;
        }

        @include breakpoint(small) {
            flex-direction: column;
            padding: 30px 25px;
            gap: 25px;
        }

        @include breakpoint(mdsmall) {
            padding: 30px 40px;
            flex-direction: row;
            gap: 50px;
            border-radius: 106.235px;
        }

        .imgAviso {
            text-align: right;
        }

        .textoAviso {
            color: #008077;
        }
    }
}

#sectionF {
    display: flex;
    flex-direction: column;
    background-color: #D5E7B0;
    background-size: cover;

    .title {
        padding: $internalSpace 0 0 0;

        h2 {
            font-weight: 400;
            text-align: center;
            color: #008077;
            margin-bottom: 80px;
            font-style: italic;

            strong {
                font-weight: 600;
                color: #013853;
            }

            @include breakpoint(vrsmall) {
                font-size: 3.3125rem;
            }

            @include breakpoint(small) {
                font-size: 4.6875rem;
            }
        }
    }

    .containerF {
        width: 100vw;
        display: flex;
        flex-direction: row;

        @include breakpoint(vrsmall) {
            padding: 0 50px 0 50px;
        }

        @include breakpoint(small) {
            padding: 0 $internalSpace 0 $internalSpace;
        }

        .texto {

            a {
                background-color: #008077;
                color: #fff;
                text-decoration: none;
                padding: 5px 20px;
                border-radius: 20px;
                font-weight: 500;
                margin-left: 10px;

                &:hover {
                    background-color: #013853;
                }
            }

            h2 {
                font-weight: 300;
                font-size: 2.6875rem;
                color: #fff;
                margin-bottom: 30px;

                strong {
                    font-weight: 900;
                }
            }

            h3 {
                color: #fff;
            }

            .tipoEmpresa {
                color: #008077;
                font-weight: 600;

                span {
                    margin-bottom: 50px;
                }
            }

            p {
                margin-bottom: 30px;
                font-weight: 400;
                color: #013853;
            }

            ul {
                margin-top: 20px;
                list-style-position: inside;
                padding-left: 0;
            }

            li {
                list-style: none;
                color: #013853;
                font-weight: 400;
            }

            .asterisco {
                font-size: 1.125rem;
                font-weight: 400;
                font-style: italic;
                color: #008077;
            }
        }

        .imagem {
            align-self: flex-end;

            @include breakpoint(vrsmall) {
                display: none;
            }

            @include breakpoint(small) {
                display: none;
            }

            @include breakpoint(mdlarge) {
                display: block;
            }

            img {
                display: block;
                height: auto;

                @include breakpoint(mdsmall) {
                    max-width: 100%;
                }
            }
        }
    }
}

#sectionG {
    background: url(../imgs/bg-g.jpg) center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {
        padding: $internalSpace 0;

        .title {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: #ffffff;
            margin-bottom: 120px;

            strong {
                font-weight: 600;
                color: #D5E7B0;
            }

            @include breakpoint(vrsmall) {
                font-size: 3.4375rem;
            }

            @include breakpoint(small) {
                font-size: 4.6875rem;
            }
        }

        .duvida {
            padding: 40px;
            margin: 15px 0px;
            border: 2px solid #D5E7B0;
            border-radius: 90px;
            text-align: center;
            min-height: 190px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                font-size: 1.5625rem;
                color: #D5E7B0;
                font-weight: 900;
            }

            p {
                color: #ffffff;
                font-weight: 400;
                margin-top: 15px;
            }
        }

        h4 {
            max-width: 1000px;
            margin: 0 auto;
            font-size: 1.5625rem;
            color: #fff;
            font-weight: 600;
            font-style: italic;
            padding-top: $internalSpace;
            text-align: center;
        }
    }
}

#sectionForm {
    background: url(../imgs/bg-f.jpg) top center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $internalSpace;

    .formHeader {

        @include breakpoint(vrsmall) {
            display: flex;
            flex-direction: column;
            padding: 0 0 80px 0;
            align-items: center;
            width: 100%;

            h4 {
                text-align: center;
                padding: 25px 0 0 0;
            }
        }

        @include breakpoint(small) {
            display: flex;
            flex-direction: column;
            padding: 0 0 80px 0;
            align-items: center;
            width: 100%;

            h4 {
                text-align: center;
                padding: 25px 0 0 0;
            }
        }

        @include breakpoint(mdsmall) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 1320px;
            width: 100%;

            h4 {
                text-align: right;
            }
        }

        @include breakpoint(medium) {}

        @include breakpoint(mdlarge) {}

        .fHeaderTitle,
        h4 {
            font-weight: 300;
            color: #fff;
        }

        .fHeaderTitle {
            font-size: 2.6875rem;
        }
    }

    form {

        @include breakpoint(vrsmall) {
            width: 350px;
        }

        @include breakpoint(small) {
            width: 540px;
        }

        @include breakpoint(mdsmall) {
            width: 720px;
        }

        @include breakpoint(medium) {
            width: 960px;
        }

        @include breakpoint(mdlarge) {
            width: 1140px;
        }

        @include breakpoint(large) {
            width: 1320px;
        }

        .btnSend {
            text-align: center;
            padding: 50px 0 0 0;

            Button {
                cursor: pointer;
                width: 75%;
                padding: 15px 0;
                border-radius: 50px;
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }

    .fileUpload {
        margin-top: 50px;

        h4 {
            padding: 10px 0 10px 30px;
            color: #fff;
        }

        p {
            padding: 10px 0 10px 30px;
            color: #a2a2a2;
        }

        .erroForm {
            margin-left: 30px;
        }

        .uploadAnexo {
            width: 100%;
            background-color: #ffffff1a;
            border-radius: 50px;

            .campoStyle {
                width: 100%;
                padding: 15px;
                color: #00a0a9;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
        column-gap: 10px;
        row-gap: 20px;

        .personalColors {
            color: #fff;
        }

        .campoStyle {
            background-color: rgba(255, 255, 255, 1);
            max-height: 55px;
            border-radius: 50px;
            width: 100%;

            ::before,
            ::after {
                border: 0px;
            }

            label {
                font-family: "Montserrat", sans-serif;
                color: rgb(0, 0, 0);
                font-weight: 500;
            }
        }

        .radioGroup {
            display: flex;
            justify-content: center;
        }

        .radioStyle {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 58.5px;
            max-height: max-content;
            justify-content: center;

            span:nth-child(1) {
                color: #0d6efd;
            }

            label {
                color: #fff;

            }
        }

        @include breakpoint(vrsmall) {
            width: inherit;

            .Empresa,
            .CNPJ,
            .InscricaoEstadual,
            .Telefone,
            .CEP,
            .Endereco,
            .Bairro,
            .E-mail,
            .Faturamento,
            .Porte,
            .Atividade,
            .Associado,
            .Agencia {
                grid-column: 1 / 13;
            }

            .Estado {
                grid-column: 1 / 7;
            }

            .Cidade {
                grid-column: 7 / 13;
            }

            .Porte,
            .Atividade,
            .Associado {
                align-items: center;
            }

            .Agencia {
                text-align: center;
            }

            .cpfSocio1 {
                grid-column: 1 / 13;
            }

            .nomeSocio1 {
                grid-column: 1 / 13;
            }

            .emailSocio1 {
                grid-column: 1 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 7;
            }

            .nascimentoSocio1 {
                grid-column: 7 / 13;
            }

            .nomemaeSocio1 {
                grid-column: 1 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 7;
            }

            .sexoSocio1 {
                grid-column: 7 / 13;
            }

            .naturalidadeSocio1 {
                grid-column: 1 / 13;
            }

            .cepSocio1 {
                grid-column: 1 / 13;
            }

            .enderecoSocio1 {
                grid-column: 1 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 13;
            }

            .estadoSocio1 {
                grid-column: 1 / 5;
            }

            .cidadeSocio1 {
                grid-column: 5 / 13;
            }

            .sociedadeSocio1 {
                grid-column: 3 / 11;
            }

            .cidadeSocio2 {
                grid-column: 5 / 13;
            }

            .sociedadeSocio2 {
                grid-column: 3 / 11;
            }

            .complementoSocio1 {
                grid-column: 1 / 13;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                padding: 0 20px;
                align-items: center;
                justify-content: center;
                min-height: 150px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 1 / 13;
            }

            .rendaCj {
                grid-column: 1 / 13;
            }

            .valorCredito {
                grid-column: 1 / 13;
            }

            .nmParcelas {
                grid-column: 1/ 7;
            }

            .carencia {
                grid-column: 7 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;
                row-gap: 20px;

                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregos {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include breakpoint(small) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 7;
            }

            .CNPJ {
                grid-column: 7 / 13;
            }

            .InscricaoEstadual {
                grid-column: 7 / 13;
            }

            .Endereco {
                grid-column: 8 / 13;
            }

            .Telefone,
            .Bairro {
                grid-column: 1 / 5;
            }

            .CEP {
                grid-column: 5 / 8;
            }

            .Estado {
                grid-column: 5 / 9;
            }

            .Cidade {
                grid-column: 9 / 13;
            }

            .E-mail {
                grid-column: 1 / 6;
            }

            .Faturamento {
                grid-column: 6 / 13;
            }

            .Porte,
            .Atividade,
            .Associado {
                grid-column: 1 / 13;
                align-items: center;
            }

            .Agencia {
                grid-column: 1 / 13;
                text-align: center;
            }

            .cpfSocio1 {
                grid-column: 1 / 5;
            }

            .nomeSocio1 {
                grid-column: 5 / 13;
            }

            .emailSocio1 {
                grid-column: 1 / 9;
            }

            .telefoneSocio1 {
                grid-column: 9 / 13;
            }

            .nascimentoSocio1 {
                grid-column: 1 / 5;
            }

            .nomemaeSocio1 {
                grid-column: 5 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 5;
            }

            .sexoSocio1 {
                grid-column: 5 / 8;
            }

            .naturalidadeSocio1 {
                grid-column: 8 / 13;
            }

            .cepSocio1 {
                grid-column: 1 / 5;
            }

            .enderecoSocio1 {
                grid-column: 5 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 5;
            }

            .estadoSocio1 {
                grid-column: 5 / 8;
            }

            .cidadeSocio1 {
                grid-column: 8 / 13;
            }

            .sociedadeSocio1 {
                grid-column: 4 / 10;
            }

            .cidadeSocio2 {
                grid-column: 8 / 13;
            }

            .sociedadeSocio2 {
                grid-column: 8 / 13;
            }

            .complementoSocio1 {
                grid-column: 1 / 8;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                padding: 0 20px;
                align-items: center;
                justify-content: center;
                min-height: 150px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 8 / 13;
            }

            .rendaCj {
                grid-column: 4 / 10;
            }

            .valorCredito {
                grid-column: 1 / 13;
            }

            .nmParcelas {
                grid-column: 1 / 7;
            }

            .carencia {
                grid-column: 7 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;
                row-gap: 20px;

                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregos {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include breakpoint(mdsmall) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 13;
            }

            .CNPJ{
                grid-column: 1 / 13;
            }

            .InscricaoEstadual {
                grid-column: 1 / 13;
            }

            .Telefone{
                grid-column:1 / 13;
            }

            .Bairro {
                grid-column: 10 / 13;
            }

            .CEP,
            .Estado {
                grid-column: 1 / 5;
            }

            .Endereco {
                grid-column: 5 / 10;
            }

            .Cidade {
                grid-column: 5 / 8;
            }

            .E-mail {
                grid-column: 8 / 13;
            }

            .Faturamento {
                grid-column: 1 / 7;
            }

            .Porte,
            .Associado {
                grid-column: 7 / 13;
                align-items: center;
            }

            .Atividade {
                grid-column: 1 / 7;
                align-items: center;
            }

            .Agencia {
                grid-column: 2 / 12;
                text-align: center;
            }

            .cpfSocio1 {
                grid-column: 1 / 4;
            }

            .nomeSocio1 {
                grid-column: 4 / 9;
            }

            .emailSocio1 {
                grid-column: 9 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 4;
            }

            .nascimentoSocio1 {
                grid-column: 4 / 7;
            }

            .nomemaeSocio1 {
                grid-column: 7 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 4;
            }

            .sexoSocio1 {
                grid-column: 4 / 6;
            }

            .naturalidadeSocio1 {
                grid-column: 6 / 9;
            }

            .cepSocio1 {
                grid-column: 9 / 13;
            }

            .enderecoSocio1 {
                grid-column: 1 / 7;
            }

            .bairroSocio1 {
                grid-column: 7 / 11;
            }

            .estadoSocio1 {
                grid-column: 11 / 13;
            }

            .cidadeSocio1 {
                grid-column: 1 / 10;
            }

            .sociedadeSocio1 {
                grid-column: 10 / 13;
            }

            .cidadeSocio2 {
                grid-column: 1 / 5;
            }

            .sociedadeSocio2 {
                grid-column: 10 / 13;
            }

            .complementoSocio1 {
                grid-column: 5 / 10;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                align-items: center;
                justify-content: center;
                min-height: 100px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 10 / 13;
            }

            .rendaCj {
                grid-column: 4 / 10;
            }

            .valorCredito {
                grid-column: 1 / 6;
            }

            .nmParcelas {
                grid-column: 6 / 9;
            }

            .carencia {
                grid-column: 9 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;

                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas  
                    column-gap: 10px;
                    align-items: center;
                    width: 100%;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregados {
                        grid-column: 1 / 7;
                    }

                    .quantosEmpregos {
                        grid-column: 7 / 13;
                        margin-top: 0;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 5;
            }

            .CNPJ {
                grid-column: 5 / 10;
            }

            .InscricaoEstadual {
                grid-column: 5 / 10;
            }

            .Bairro {
                grid-column: 7 / 10;
            }

            .CEP,
            .Cidade {
                grid-column: 1 / 3;
            }

            .Endereco {
                grid-column: 3 / 7;
            }

            .Estado,
            .Telefone {
                grid-column: 10 / 13;
            }

            .E-mail {
                grid-column: 3 / 6;
            }

            .Faturamento {
                grid-column: 6 / 10;
            }

            .Porte {
                grid-column: 10 / 13;
                padding: 0 0 0 5px;
            }

            .Atividade {
                grid-column: 1 / 6;
                align-self: center;
                padding: 5px 0;
            }

            .Associado {
                grid-column: 6 / 10;
                align-self: center;
                padding: 5px 0;
            }

            .Agencia {
                grid-column: 10 / 13;
            }

            .cpfSocio1 {
                grid-column: 1 / 4;
            }

            .nomeSocio1 {
                grid-column: 4 / 10;
            }

            .emailSocio1 {
                grid-column: 10 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 4;
            }

            .nascimentoSocio1 {
                grid-column: 4 / 7;
            }

            .nomemaeSocio1 {
                grid-column: 7 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 3;
            }

            .sexoSocio1 {
                grid-column: 3 / 4;
            }

            .naturalidadeSocio1 {
                grid-column: 4 / 6;
            }

            .cepSocio1 {
                grid-column: 6 / 8;
            }

            .enderecoSocio1 {
                grid-column: 8 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 5;
            }

            .estadoSocio1 {
                grid-column: 5 / 7;
            }

            .cidadeSocio1 {
                grid-column: 7 / 10;
            }

            .sociedadeSocio1 {
                grid-column: 10 / 13;
            }

            .cidadeSocio2 {
                grid-column: 7 / 10;
            }

            .sociedadeSocio2 {
                grid-column: 5 / 10;
            }

            .complementoSocio1 {
                grid-column: 10 / 13;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                align-items: center;
            }

            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 1 / 7;
            }

            .rendaCj {
                grid-column: 7 / 13;
            }

            .valorCredito {
                grid-column: 1 / 5;
            }

            .nmParcelas {
                grid-column: 5 / 8;
            }

            .carencia {
                grid-column: 8 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;

                .fCredito {
                    grid-column: 1 / 7;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 7 / 13;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas  
                    column-gap: 10px;
                    row-gap: 20px;
                    align-items: center;
                    width: 100%;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        grid-column: 3 / 13;
                        width: 100%;
                    }
                }
            }
        }

        @include breakpoint(mdlarge) {
            width: inherit;
        }

        @include breakpoint(large) {
            width: inherit;
        }
    }

    .socioTitle {
        border-bottom: 2px solid #fff;
        color: #ffffff;
        margin: 50px 0;
        padding: 10px 0;
        width: inherit;
    }

    .conjugeTitle {
        color: #ffffff;
        margin: 50px 0;
        padding: 10px 0;
        width: inherit;

        h2 {
            border-bottom: 2px solid #fff;
            padding-bottom: 10px;
            margin-bottom: 50px;
        }
    }

    .addSocio {
        width: 100%;
        margin: 50px 0;
        padding: 25px 0;
        background-color: #F9A825;
        font-size: 1.125rem;
        color: #000;

        &:hover {
            background-color: #F57F17;
            color: #fff;
        }
    }

    .exclusaoSocio {
        width: 100%;
        display: flex;
        justify-content: right;

        .excluiSocio {
            margin: 0 0 25px 0;
            background-color: #C62828;
            padding: 15PX 10PX;

            &:hover {
                background-color: #D50000;
            }
        }
    }
}

.termosAceite {
    margin: 50px 0;

    .termosGroup {
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
        color: #fff;
        font-family: Roboto, Helvetica, Arial, sans-serif;

        .verificaPolitica {
            display: flex;
            align-items: center;

            .checkbox {
                svg {
                    margin-right: 15px;
                }
            }

            p {
                a {
                    color: #1976d2;
                    font-weight: 500;
                }
            }
        }
    }

    .checkbox {
        padding: 25px 0 25px 15px;

        svg {
            color: #fff;
            margin-right: 25px;
        }
    }

    .popupctd {
        position: relative;
        color: #1a1a1a;
        max-width: 100%;

        h3 {
            padding-bottom: 15px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .docSolicita,
    .docAnalise {
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        font-size: 1.875rem;
    }

    .docSolicita {
        padding: 50px 0 50px 0;
        font-style: italic;
        font-weight: 400;
        color: #D5E7B0;
    }

    .docAnalise {
        font-weight: 600;
        font-style: italic;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.50);
    display: flex;
    justify-content: center;
    z-index: 10000;
    overflow-y: scroll;

    &-inner {
        position: relative;
        padding: 32px;
        height: max-content !important;
        max-width: 1000px;
        background-color: #fff;
    }
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(vrsmall) {
        row-gap: 10px;
    }

    img {
        max-width: 138px;
        display: block;
        height: auto;

        @include breakpoint(vrsmall) {
            max-width: 118px;
        }
    }
}