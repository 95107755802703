@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_italic-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_bold-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_italic-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_bold-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format('woff'),
        url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
}

@mixin breakpoint($name) {
    @if $name =="vrsmall" {
        @media (max-width: 574px) {
            @content;
        }
    }

    @else if $name =="small" {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $name =="mdsmall" {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $name =="medium" {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $name =="mdlarge" {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else if $name =="large" {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'esphimere', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
    overflow-x: hidden;
    min-height: 100vh;
}

p {
    font-size: 1.265rem;

    @include breakpoint(vrsmall) {
        font-size: 1.115rem;
    }

    @include breakpoint(small) {
        font-size: 1.12rem;
    }

    @include breakpoint(medium) {
        font-size: 1.125rem;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}